import React, { FC, useContext, useEffect, useState } from 'react'
import * as pageUtils from '@components/homepage/__index.utils'
import { clockIcon, myRequestGreyIcon, userGrayIcon } from '@images/icons'
import { formatStrapiText, timeDisplay } from '@utils/methods'
import { Icon } from '@components/ui/Icon'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Link from '@components/ui/link'
import { joinClasses } from '@utils/styles'
import { Collapse } from 'react-collapse'
import { ActivityLogItem } from '@services/models/_miscellaneous.model'
import Loader from '@components/ui/loader'
import config from '@utils/config'
import { Request } from 'typings/shared'

const HomepageSidebarActivities: FC<pageUtils.HomepageSidebarActivitiesProps> =
	() => {
		const { pageData, authUser, language } =
			useContext<AppContextProps>(AppStateContext)
		const [showContent, setShowContent] = useState<boolean>(true)
		const [addClassOnShowContent, setAddClassOnShowContent] =
			useState<boolean>(true)
		const [isLoading, setIsLoading] = useState<boolean>(true)
		const [activities, setActivities] = useState<ActivityLogItem[]>([])

		const onToggleContent = () => {
			setShowContent(!showContent)
		}

		const getRequestTitleByRequestTypeId = (typeId: string): string => {
			const requestFound: Request | undefined = pageData.requests.filter(
				(r) => r.requestId === typeId
			)[0]

			return requestFound ? formatStrapiText(requestFound.title) : ''
		}

		const fetchRecentActivities = async () => {
			if (authUser) {
				try {
					const recentActivities: ActivityLogItem[] =
						await authUser.fetchRecentActivities()

					setActivities(recentActivities || [])

					setIsLoading(false)
				} catch (e: any) {
					console.error(`[ERROR] ${e?.message}`)
					setIsLoading(false)
				}
			}
		}

		const onCollapseActionOnWork = () => {
			if (showContent) {
				return setAddClassOnShowContent(true)
			}
		}

		const onCollapseActionOnRest = () => {
			if (!showContent) {
				return setAddClassOnShowContent(false)
			}

			return setAddClassOnShowContent(true)
		}

		const getPageAssetsLabelByStatusId = (statusId: string): string => {
			if (statusId) {
				const keyLabel: string = pageUtils.PageAssetsStatusLabels[statusId]

				return `${formatStrapiText(pageData?.assets[keyLabel])}`
			}

			return formatStrapiText(
				pageData?.assets?.homepage_recent_activities_no_status
			)
		}

		const showActivity = (activity: ActivityLogItem, key: number) => {
			const date: string = timeDisplay(
				language as string,
				activity.dateAdd?.UTCDateString || '',
				pageData?.assets?.label_at
			)

			return (
				<div
					className={pageUtils.homepageSidebarActivitiesClasses.item}
					key={key}
				>
					<p className={pageUtils.homepageSidebarActivitiesClasses.date}>
						{date}
					</p>
					<div>
						{formatStrapiText(
							pageData?.assets?.homepage_sidebarActivities_statusRequestText
						)}{' '}
						<Link
							to={activity.link}
							className={pageUtils.homepageSidebarActivitiesClasses.link}
						>
							{activity.code}
							{' - '}
							{getRequestTitleByRequestTypeId(activity.requestTypeId)}
						</Link>{' '}
						{formatStrapiText(
							pageData?.assets?.homepage_sidebarActivities_modifyToText
						)}{' '}
						<strong>{getPageAssetsLabelByStatusId(activity.statusId)}</strong>
					</div>
				</div>
			)
		}

		useEffect(() => {
			fetchRecentActivities()
		}, [authUser])

		return (
			<>
				<div
					className={joinClasses([
						pageUtils.homepageSidebarActivitiesClasses.root,
						pageUtils.homepageSidebarActivitiesClasses.rootActivities
					])}
				>
					<div
						className={joinClasses([
							pageUtils.homepageSidebarActivitiesClasses.container,
							pageUtils.homepageSidebarActivitiesClasses.containerActivities
						])}
					>
						<div
							className={pageUtils.homepageSidebarActivitiesClasses.title}
							onClick={onToggleContent}
						>
							<Icon src={clockIcon} />
							{formatStrapiText(
								pageData?.assets?.homepage_sidebarActivities_title
							)}
						</div>
						<div
							className={joinClasses([
								pageUtils.homepageSidebarActivitiesClasses.collapseContent,
								addClassOnShowContent
									? pageUtils.homepageSidebarActivitiesClasses.content
									: '',
								showContent && isLoading
									? pageUtils.homepageSidebarActivitiesClasses.contentLoading
									: ''
							])}
						>
							<Collapse
								isOpened={showContent}
								onWork={onCollapseActionOnWork}
								onRest={onCollapseActionOnRest}
							>
								{isLoading && <Loader text={pageData?.assets?.loading} />}
								{!isLoading && activities.map(showActivity)}
								{!isLoading && activities.length === 0 && (
									<>{pageData?.assets?.activities_notFound}</>
								)}
							</Collapse>
						</div>
					</div>
				</div>
			</>
		)
	}

export default HomepageSidebarActivities
