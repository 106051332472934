import React, { FC, useContext, useEffect, useState } from 'react'
import * as pageUtils from '@components/homepage/__index.utils'
import { QuickAccess } from 'typings/shared'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import Link, { navigate } from '@components/ui/link'
import config from '@utils/config'
import Text from '@components/ui/text'
import { formatStrapiText } from '@utils/methods'
import { HiddenEnum, LanguageEnum } from '@services/constants'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { loginRequest, authRedirectPage } from '@utils/authConfig'
import { Icon } from '@components/ui/Icon'
import { myRequestGreyIcon, userGrayIcon } from '@images/icons'
import { joinClasses } from '@utils/styles'
import axios from 'axios'
import Loader from '@components/ui/loader'

const HomepageQuickAccess: FC<pageUtils.HomepageQuickAccessProps> = ({
	quickAccesses
}) => {
	const { instance } = useMsal()
	const { pageData, language } = useContext<AppContextProps>(AppStateContext)
	const isUserConnected: boolean = useIsAuthenticated()

	const [listQuickAcces, setListQuickAccess] = useState<QuickAccess[]>([])
	const [listVisibleQuickAccess, setListVisibleQuickAccess] = useState<
		QuickAccess[]
	>([])
	const [loading, setLoading] = useState<boolean>(false)

	const onNavigateToRequest = (requestId: string) => () => {
		const link: string = `${config.request.create.baseURL}/${requestId}`

		if (!isUserConnected) {
			const authRedirect: string = config.localStorage.authRedirect
			// this will send the right language to display on Azure AD B2C
			const extraQueryParameters = {
				ui_locales: `${language || LanguageEnum.FR}`
			}

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	const displayQuickAccess = (quickAccess: QuickAccess, key: number) => (
		<div
			onClick={onNavigateToRequest(quickAccess?.requestId)}
			className={pageUtils.homepageQuickAccessClasses.item}
			key={key}
		>
			<img
				src={quickAccess?.iconUrl}
				alt={formatStrapiText(quickAccess?.title)}
			/>
			<p className="title">{formatStrapiText(quickAccess?.title)}</p>
			<p className="description">
				{formatStrapiText(quickAccess?.description)}
			</p>
		</div>
	)

	const onChangeSetListAccess = () => {
		setListQuickAccess(
			quickAccesses.filter((quickAccess: QuickAccess) =>
				quickAccess.language.includes(String(language).substring(0, 2))
			)
		)
	}

	useEffect(() => {
		onChangeSetListAccess()
	}, [language])

	useEffect(() => {
		setLoading(true)
		axios
			.get(
				`${process.env.STRAPI_API}/api/ptc-quick-accesses?populate=*&filters[cacher][$eq]=Non&locale=${language}`
			)
			.then(async (res) => {
				const data = res.data as pageUtils.strapiDataRes

				// Create a new list of quick accesses based on the current Strapi get (instead of pageAssets)
				let quicks = data.data.map((element) => {
					let attr: any = element.attributes

					return {
						title: attr?.title,
						description: attr?.description,
						cacher: attr?.cacher,
						order: attr?.order,
						language: attr?.locale,
						requestId: attr?.ptc_request?.data?.attributes?.request_id,
						iconUrl: attr?.icon?.data?.attributes?.url
					}
				})

				const quickAccessesOrders: number[] = data.data.map(
					(request) => request.attributes.order
				)
				let visibleQuickAccesses = quicks.filter((quickAccess) =>
					quickAccessesOrders.includes(quickAccess.order)
				)

				visibleQuickAccesses.sort((a, b) => {
					const orderA =
						data.data.find((item) => item.attributes.title === a.title)
							?.attributes.order || a.order
					const orderB =
						data.data.find((item) => item.attributes.title === b.title)
							?.attributes.order || b.order

					return orderA - orderB
				})

				setListVisibleQuickAccess(visibleQuickAccesses as QuickAccess[])
				setLoading(false)
			})
			.catch((e) => {
				console.error(e)
			})
	}, [listQuickAcces])

	return (
		<div className={pageUtils.homepageQuickAccessClasses.root}>
			<h2 className={pageUtils.homepageQuickAccessClasses.sectionTitle}>
				<Text content={pageData?.assets?.homepage_quickAccess_title} />
			</h2>

			{loading && (
				<div>
					<Loader text={pageData?.assets?.loading} />
				</div>
			)}

			{!loading && !!listVisibleQuickAccess.length && (
				<div className={pageUtils.homepageQuickAccessClasses.list}>
					{listVisibleQuickAccess.length &&
						listVisibleQuickAccess.map(displayQuickAccess)}
				</div>
			)}
		</div>
	)
}

export default HomepageQuickAccess
